<template>
  <div>
    <modalChofer
      :modal.sync="showModal"
      @updated-modal="updateCLoseModal"
      :title="titleModal"
      :editModal="editModal"
      @refresh-data-table="resetDataOfTable"
      :diverSelected="diverSelected"
    />

    <CRow>
      <CCol sm="12" class="d-flex justify-content-end py-2">
        <CButton
          color="add"
          class="d-flex align-items-center"
          @click="toggleShowModalCreateChofer"
          v-c-tooltip="{
            content: $t('label.driver'),
            placement: 'top-end'
          }"
        >
          <CIcon name="cil-playlist-add"/><span class="ml-1">{{$t('label.nuevo')}}</span>
        </CButton>
      </CCol>
    </CRow>
    <dataTableExtended
     class="align-center-row-datatable"
      size="lg"
      :fields="fields"
      :items="computedDriverList"  
      sorter
      :items-per-page-select="tableText.itemsPerPageText"
      :items-per-page="tableText.itemsPerPage"
      :noItemsView="tableText.noItemsViewText"
      :loading="loadingTable"
      @pagination-change="changePagination($event)"
      hover
      :paginator="{ TotalPages: totalPages, TotalRecords: totalRecords, Page: page}"
    >
      <template #over-table>
        <div class="row justify-content-start" style="margin-top: -2.8rem">
          <div class="col-auto px-2">
            <label class="p-1 mt-1">{{  $t('label.filter') }}<b>: </b></label>
          </div>
          <div class="col-auto px-0">
            <CInput
              v-model="searchKey"
              :placeholder="$t('label.enterSearch')"             
              v-uppercase
              class="col-sm-12 px-0"
            />
          </div>
          <div class="col-auto px-2">
            <CButton
              color="watch"
              class="d-flex align-items-center"
              v-c-tooltip="{
                content: $t('label.search')+' '+$t('label.driver'),
                placement: 'top',
              }"
              @click="searchAdvanceFilter"
            >
              <CIcon name="cil-search" />
            </CButton>
          </div>
          <div class="col-auto px-0">
            <CButton
              color="edit"
              class="d-flex align-items-center"
              v-c-tooltip="{
                content: $t('label.reset')+$t('label.table'),
                placement: 'top',
              }"       
                @click="resetDataOfTable"
            >
              <CIcon name="cil-loop-circular" />
            </CButton>
          </div>     
        </div>        
      </template>
      <template #loading>
        <loading/>
      </template>
      <template #Status="{ item }">
        <td class="center-cell">
          <CBadge :color="getBadge(item.Status)">
            {{ $t('label.'+item.Status) }}
          </CBadge>
        </td>
      </template>

      <template #options="{ item }">
        <td class="center-cell">
          <CButton
            color="edit"
            size="sm"
            v-c-tooltip="{
              content: $t('label.edit')+' '+$t('label.driver'),
              placement: 'top-end'
            }"
            @click="toggleShowModalEditDriver(item)"
          >
            <CIcon name="pencil" />
          </CButton>
        </td>
      </template>
      <!--paginacion-->
      <template #under-table>
        <div v-if="totalPages > 0 "  class="ml-3 mb-3">
          <CPagination
            :activePage.sync="page"
            :pages="totalPages"
            size="sm"
            @update:activePage="updatePage($event)"
          />
        </div>
      </template>
    </dataTableExtended>

  </div>
</template>
<script>
import { DateFormater } from '@/_helpers/funciones';
import GeneralMixin from '@/_mixins/general';
import ModalMixin from '@/_mixins/modal';
import modalChofer from './modal-chofer';
import UpperCase from '@/_validations/uppercase-directive';

function data() {
  return {
    itemsDrivers: [],
    loadingTable: false,
    loadingOverlay: false,
    showModal: false,
    editModal: false,
    titleModal: '',
    page: 1,
    recordPages: 5,
    totalPages: 0,
    totalRecords: 0,
    diverSelected: {},
    searchKey: '',
    resetData: false,
  };
}

function fields() {
  return [
    { label: '#', key: 'Nro', filter: false, _style: 'width:1%; text-align:center;', _classes: 'text-center center-cell' },
    { label: this.$t('label.IdNumber'), key: 'DriverCi', _classes:'text-uppercase text-center center-cell' },
    { label: this.$t('label.driver'), key: 'DriverName', _classes:'text-uppercase text-center center-cell' },
    { label: this.$t('label.user'), key: 'TransaLogin', _classes:'text-uppercase text-center center-cell'  },
    { label: this.$t('label.date'), key: 'FormatedDate', _style: 'width:1%; ', _classes:'text-center text-center center-cell'  },
    { label: this.$t('label.status'), key: 'Status', _style: 'width:1%; ', _classes: 'text-center center-cell' },
    { key: 'options', label: '', sorter: false, filter: false, _style: 'width:1%; ', _classes:'text-center center-cell'},
  ]
}

//### ACtualiza el estado una vez cerrado el modal
function updateCLoseModal(event) {
  this.showModal = false;
  this.titleModal = '';
  this.editModal = false;
  this.diverSelected = {};
}
//activa el modal de editar
function toggleShowModalEditDriver(item) {
  this.showModal = true;
  this.diverSelected = item;
  this.titleModal = this.$t('label.edit')+' '+this.$t('label.driver')+': '+this.diverSelected.DriverName+' - '+this.diverSelected.DriverCi;
  this.editModal = true;
}

//## Activa el modal para crear nuevo Estado
function toggleShowModalCreateChofer() {
  this.showModal = true;
  this.titleModal = this.$t('label.nuevo')+' '+this.$t('label.driver');
}
//reinicia el datatable
function resetDataOfTable() {
  this.resetData = true;
  this.page = 1;
  this.searchKey= '',
  this.mountedDriverItens(true);
}
//cuando presiono el boton de busqueda
function searchAdvanceFilter() {  
  if (this.searchKey.length > 0) {
    this.resetData = true;
    this.page = 1;
    this.mountedDriverItens(true);
  } 
}

//cuando cambia la paginacion. (esta en el datatable)
// hace la consulta de acuerdo a la cantidad de registros por pagina. 
async function changePagination(event) {
  this.page = 1;
  this.recordPages = event;
  await this.mountedDriverItens(true);
}

//cuando cambio de pagina. (esta en le paginador)
//actualiza los registros de la pagina seleccionada 
async function updatePage(event) {
  this.page = event; 
  await this.mountedDriverItens(true);
}

//consulta 
async function mountedDriverItens(value = false) {
  this.loadingOverlay = true;
  let json = '';

  if (!value) {
    this.resetData = false;
    json = JSON.stringify({ DriverJson: [{ SearchKey: '' }] });
  } else {
    json = JSON.stringify({ DriverJson: [{ SearchKey: this.searchKey }] });
  }

  this.loadingTable = true;

  await this.$http
    .get(
      `Driver-list?PageNumber=${this.page}&TotalRecords=${this.recordPages}&fgList=1&DriverJson=${json}`
    )

    .then((response) => {
      if (response.data.data !== null) {
        this.itemsDrivers = response.data.data.data;
        this.totalPages = response.data.data.TotalPages;
        this.totalRecords = response.data.data.TotalRecords;
      } else {
        this.itemsDrivers=[];
        this.loadingTable = false;
        this.totalPages = 0;
        this.totalRecords = 0;
      }
      this.loadingOverlay = false;
    })
    .finally(() => {
      this.loadingTable = false;
    })
    .catch((e) => {
      this.loadingTable = false;
      this.notifyError({ text: e,});
    });
}

//### Funcion computed para formatear los datos de la tabla ###
function computedDriverList() {
  if (this.itemsDrivers.length > 0) {
    return this.itemsDrivers.map((item) => {
      return {
        ...item,
        FormatedDate: item.TransaRegDate
          ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate)
          : 'N/A',
        _classes: item.Status != 'ACTIVO' ? 'table-danger' : '',
      };
    });
  }
}

export default {
  name: 'choferes',
  data,
  mixins: [
    GeneralMixin,
    ModalMixin,
  ],
  components: {
    modalChofer,
  },
  directives: UpperCase,
  methods: {
    toggleShowModalCreateChofer,
    updateCLoseModal,
    mountedDriverItens,
    changePagination,
    updatePage,
    toggleShowModalEditDriver,
    resetDataOfTable,
    searchAdvanceFilter,
  },
  computed: {
    computedDriverList,
    fields,
  },
  mounted: mountedDriverItens,
};
</script>
<style lang="scss">
.center-cell {
  text-align: center;
}
 
</style>
